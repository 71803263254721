<template>
    <div
        class="code"
        :class="{
            'code_wait': timer
        }"
    >
        <Field
            title="Код из СМС"
            v-model="form.code"
            :error="formErrors.code"
            autocomplete="one-time-code"
            mask="#####"
            class="code__field"
            center
            autofocus
            placeholder="12345"
        />
        <span
            v-if="timer"
            class="code__repeat"
        >
            <span class="icon-repeat"></span>
            Действителен {{ timer }} с
        </span>
        <span
            v-else
            class="code__repeat"
            @click="initTimer(false)"
        >
            Выслать СМС повторно
        </span>
    </div>
</template>

<script>
const seconds = 1 * 60;
const miliseconds = seconds * 1000;

import './code.scss'

import validation from '@/mixins/validation'
import Validation from '@/ext/validation/Validation'

import Storage from '@/ext/storage/Storage'

export default {
    name: 'Code',
    props: {
        type: {
            type: String,
            required: true
        },
        getCode: {
            type: Function,
            default: () => {}
        },
        notInitSend: {
            type: Boolean,
            default: false
        }
    },
    mixins: [
        validation,
    ],
    created() {
        this.initTimer(true, !this.notInitSend);
    },
    data() {
        return {
            form: {
                code: ''
            },
            formRules: {
                code: [
                    Validation.REQUIRED,
                    [Validation.MIN, 5]
                ]
            },
            timer: 0
        }
    },
    watch: {
        'form.code'(v) {
            v.length === 5 && this.$emit('ready')
        }
    },
    methods: {
        async initTimer(flag = true, getCode = true) {
            let timer = Storage.get(`timer-${this.type}`)

            if (flag && timer && +timer < +new Date() + miliseconds) {
                this.setTimer(Math.ceil((timer - +new Date()) / 1000));
            } else {
                if (getCode) {
                    await this.getCode();
                }

                this.setTimer(seconds);
                Storage.set(`timer-${this.type}`, +new Date() + miliseconds);
            }
        },
        resetTimer() {
            Storage.delete(`timer-${this.type}`)
        },
        setTimer(time) {
            if (time < 0)
                time = 0

            this.timer = time;

            if (time === 0)
                return

            setTimeout(() => {
                this.setTimer(time - 1)
            }, 1000)
        }
    }
}
</script>