<template>
    <StepTemplate
        :isLong="true"
        modifier="city"
    >
        <template v-slot:headerTitle>
            Код из СМС
        </template>
        <ActionForm
            @submit="validate"
            actionName="Войти"
        >
            <template v-slot:desc>
                Введите код из СМС для авторизации
            </template>
            <Code
                :getCode="getCode"
                @ready="submit"
                ref="code"
                type="login"
                notInitSend
                autofocus
            />
        </ActionForm>
    </StepTemplate>
</template>

<script>
import auth from '@/api/auth'

import ActionForm from '@/templates/action-form/ActionForm'
import Code from '@/components/form/code/Code'
import Store from '@/store'

import { mapGetters } from 'vuex'

export default {
    name: 'CodePage',
    computed: {
        ...mapGetters({
            code_hash: ['application/code']
        })
    },
    methods: {
        validate() {
            this.$refs.code.validate()

            this.$refs.code.isValid && this.submit()
        },
        async submit() {
            const { wrongCode, result } = await auth({
                ...this.$route.params.data,
                code: this.$refs.code.form.code,
                code_hash: this.code_hash
            })

            if (wrongCode) {
                this.$refs.code.validate();
                this.$refs.code.formErrors.code = 'Неверный код'
                return
            }

            if (result) {
                this.$router.push({
                    name: 'PersonalAreaProfile'
                })
            }
        },
        getCode() {
            auth(this.$route.params.data)
        }
    },
    components: {
        ActionForm,
        Code,
    },
    beforeRouteEnter(to, from, next) {
        const {
            data
        } = to.params

        if (data) {
            next()
        } else {
            Store.commit('application/load', false)
            next({
                name: 'Login'
            })
        }
    }
}
</script>